<template>
  <v-btn
    text
    :color="$vuetify.breakpoint.mobile ? 'white' : 'primary lighten-1'"
    class="font-weight-bold text-none text-body-2 pa-4 mt-5 text-decoration-underline d-flex text-wrap break-word"
    href="https://conectasuite.com/perguntas-frequentes/conecta-suite/escopos-google-workspace/"
    target="_blank"
  >
    <span class="font-montserrat" style="white-space: normal">
      {{ $t("action.aboutScopes") }}
    </span>
  </v-btn>
</template>
<script>
export default {
  name: "AboutScopesButton",
};
</script>
