<template>
  <div
    v-if="!$vuetify.breakpoint.md"
    :class="`${left ? '' : 'ml-auto'} ${
      centered ? 'd-flex justify-center mx-auto' : ''
    } font-weight-bold pa-7 ${colors ? '' : 'features-block'}  ${
      $vuetify.breakpoint.mobile ? 'mx-auto px-3' : 'mr-0'
    } ${customBlockClass}`"
    :style="`${centered ? 'max-width:300px;' : ''}`"
  >
    <div
      :class="`d-flex flex-column ${
        centered ? 'align-center justify-center' : 'align-end'
      } mx-auto`"
    >
      <span
        v-for="({ text, background_class }, index) in mainFeatures"
        :key="index"
        :class="`font-montserrat features ${
          colors ? background_class : ''
        } ${customFeatureClass}`"
      >
        {{ $t(text) }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginFeaturesList",

  props: {
    centered: { type: Boolean, default: false },
    colors: { type: Boolean, default: false },
    customBlockClass: { type: String, default: "" },
    customFeatureClass: { type: String, default: "" },
    left: { type: Boolean, default: false },
  },

  data() {
    return {
      mainFeatures: [
        {
          text: "login.businessManagementSystem",
          background_class: "suite-feature",
        },
        {
          text: "login.riskReduction",
          background_class: "sign-feature",
        },
        {
          text: "login.agileUserManagement",
          background_class: "control-feature",
        },
        {
          text: "login.moreSecurity",
          background_class: "more-features",
        },
      ],
    };
  },
};
</script>
<style>
.features {
  color: white;
  opacity: 0.9;
  text-align: center;
  margin-bottom: 7px;
  font-size: 20px;
  padding: 5px 15px;
  display: block;
}

@media (max-width: 700px) {
  .features {
    margin-bottom: 5px;
    font-size: 18px;
    padding: 3px 10px;
  }
}

.suite-feature {
  background-color: rgba(78, 58, 100, 0.6);
}

.sign-feature {
  background-color: rgba(207, 171, 10, 0.4);
}

.control-feature {
  background-color: rgba(31, 104, 172, 0.4);
}

.more-features {
  background-color: rgba(226, 104, 23, 0.5);
}

.features-block {
  color: #92869b;
  margin-bottom: 25px;
}
</style>
