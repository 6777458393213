var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"color":'transparent'}},[_c('v-card-text',{class:`text-center caption ${
      _vm.isMobile
        ? 'login-footer-mobile white--text'
        : 'secondary--text text--lighten-2'
    }`},[_c('span',{staticClass:"font-montserrat"},[_vm._v(" "+_vm._s(_vm.$t("login.createAccountTerms"))+" "),_c('a',{class:`primary--text font-weight-bold px-1 py-0 ${
          _vm.isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`,attrs:{"href":"https://conectasuite.com/termos-de-uso","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("login.termsOfUse"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("common.and"))+" "),_c('a',{class:`primary--text font-weight-bold px-1 py-0 ${
          _vm.isMobile ? 'accent--text' : 'primary--text'
        } text-wrap break-word`,attrs:{"href":"https://conectasuite.com/politica-de-privacidade","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("login.privacyPolicy"))+" ")]),_c('SelectLanguage')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }