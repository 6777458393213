<template>
  <v-card tile flat color="transparent" class="ma-auto" max-width="400">
    <v-card-actions class="px-4 justify-center">
      <LoginWithGoogle />
    </v-card-actions>

    <v-card-actions class="px-4 justify-center">
      <LoginWithMicrosoft />
    </v-card-actions>

    <!-- BOTÃO DE ESCOPOS -->
    <v-card-actions class="px-4 justify-center">
      <AboutScopesButton />
    </v-card-actions>

    <!-- BOTÃO DE INSTALAÇÂO -->
    <v-card-actions class="px-4 pt-0 justify-center">
      <InstallAppButton />
    </v-card-actions>
  </v-card>
</template>
<script>
import AboutScopesButton from "@/components/forms/buttons/AboutScopesButton.vue";
import LoginWithGoogle from "@/components/forms/buttons/LoginWithGoogle.vue";
import LoginWithMicrosoft from "@/components/forms/buttons/LoginWithMicrosoft.vue";
import InstallAppButton from "@/components/forms/buttons/InstallAppButton.vue";

export default {
  name: "LoginActions",

  components: {
    AboutScopesButton,
    LoginWithGoogle,
    InstallAppButton,
    LoginWithMicrosoft,
  },
};
</script>
